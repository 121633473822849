import React, { useEffect } from "react";
import { observer } from "mobx-react";

import useStores from "@hooks/useStores";

import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import useStyles from "@hooks/useStyles";

const InputCheckbox = observer(({ question, answer, secondary }) => {
	const classes = useStyles("LeadFunnel");
	const { inputCheckboxStore: store } = useStores();
	const instance = store.findInstanceByAnswerId(answer._id);

	const onChangeHandler = (e) => {
		if (e.target.checked) instance.add(e.target.value);
		else instance.remove();
	};

	useEffect(() => {
		store.init(question, answer);
		return () => store.reset();
	}, []);

	return (
		<React.Fragment>
			{instance && (
				<div className={secondary ? classes.inputBorder2 : classes.inputBorder}>
					<FormControlLabel
						classes={{
							root: secondary ? classes.formControl2 : classes.formControl,
						}}
						className={instance.isChecked && secondary && classes.checked}
						control={
							<Checkbox
								color={"primary"}
								checked={instance.isChecked}
								id={answer._id}
								value={answer.value}
								onChange={onChangeHandler}
							/>
						}
						label={answer.value}
					/>
				</div>
			)}
		</React.Fragment>
	);
});

export default InputCheckbox;
