const req = require.context(
	"@components/Main/Shared/LeadFunnel/Answers",
	true,
	/index\.(js)$/
);
const paths = req.keys();

export default paths.map((path) => ({
	path,
	name: path.split("/")[path.split("/").length - 2].toLowerCase(),
	value: req(path).default,
}));
