import React, { useEffect } from "react";
import { observer } from "mobx-react";

import useStores from "@hooks/useStores";

import DateFnsUtils from "@date-io/moment";

import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";

const DateOnlyCollapse = observer(({ question, answer, secondary }) => {
	const { dateOnlyCollapseStore: store } = useStores();
	const instance = store.findInstanceByAnswerId(answer._id);

	const onChangeHandler = (date) => instance.update("date", date);
	useEffect(() => {
		store.init(question, answer);
		store.watchCollapse(answer._id);
		return () => store.reset();
	}, []);

	return (
		<React.Fragment>
			{instance && (
				<Collapse in={!!store.collapse(answer._id)}>
					<Box p={6}>
						<MuiPickersUtilsProvider utils={DateFnsUtils}>
							<DatePicker
								minDate={Date.now()}
								variant="outlined"
								openTo="date"
								views={["year", "month", "date"]}
								label="Date"
								helperText="Choose your date"
								value={instance.date}
								onAccept={onChangeHandler}
								onChange={onChangeHandler}
							/>
						</MuiPickersUtilsProvider>
					</Box>
				</Collapse>
			)}
		</React.Fragment>
	);
});

export default DateOnlyCollapse;
