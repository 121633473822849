import React, { useEffect } from "react";
import { observer } from "mobx-react";

import useStores from "@hooks/useStores";

import Box from "@material-ui/core/Box";
import Select from "@components/Global/Custom/Select";

const SimpleDate = observer(({ question, answer, secondary }) => {
	const { dateStore: store } = useStores();
	const instance = store.findInstanceByAnswerId(answer._id);

	const timeOnChangeHandler = (e) => instance.update("time", e.target.value);
	const hoursOnChangeHandler = (e) => instance.update("hours", e.target.value);

	useEffect(() => {
		store.init(question, answer);
		return () => store.reset();
	}, []);

	return (
		<React.Fragment>
			{store.findInstanceByAnswerId(answer._id) && (
				<>
					<Box p={6} borderBottom="1px solid rgb(238, 238, 238)">
						<Box mb={6}>
							<Box component="div" mb={2} fontWeight={700}>
								At what time?
							</Box>
							<Select
								id={`${answer._id}-time`}
								value={instance.time}
								onChange={timeOnChangeHandler}
							>
								{instance.times.map((time) => (
									<option key={time} value={time}>
										{time}
									</option>
								))}
							</Select>
						</Box>
						<Box mb={3}>
							<Box component="div" mb={2} fontWeight={700}>
								For how many hours?
							</Box>
							<Select
								id={`${answer._id}-hours`}
								value={instance.hours}
								onChange={hoursOnChangeHandler}
							>
								{Array.from({ length: 11 }, (v, i) => i).map((_, i) => (
									<option key={i + 1} value={i + 1}>
										{i + 1} {i + 1 > 1 ? `hours` : "hour"}
									</option>
								))}
							</Select>
						</Box>
					</Box>
				</>
			)}
		</React.Fragment>
	);
});

export default SimpleDate;
