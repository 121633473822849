import React, { useEffect } from "react";
import { observer } from "mobx-react";

import useStores from "@hooks/useStores";

import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import TextField from "@material-ui/core/TextField";
import useStyles from "@hooks/useStyles";

const InputCheckboxText = observer(({ question, answer, secondary }) => {
	const classes = useStyles("LeadFunnel");
	const { inputCheckboxTextStore: store } = useStores();
	const instance = store.findInstanceByAnswerId(answer._id);

	const onFocusHandler = () => instance.focus();
	const textOnChangeHandler = (e) => instance.updateText(e.target.value);
	const checkboxOnChangeHandler = () => instance.toggle();

	useEffect(() => {
		store.init(question, answer);
		return () => store.reset();
	}, []);

	return (
		<React.Fragment>
			{instance && (
				<div className={secondary ? classes.inputBorder2 : classes.inputBorder}>
					<FormControlLabel
						classes={{
							root: secondary ? classes.formControl2 : classes.formControl,
							label: classes.formControlLabel,
						}}
						className={instance.isChecked && secondary && classes.checked}
						control={
							<Checkbox
								color={"primary"}
								checked={instance.isChecked}
								onChange={checkboxOnChangeHandler}
								id={answer._id}
								value={answer.value}
							/>
						}
						label={
							<TextField
								fullWidth
								inputRef={
									instance.isChecked
										? (input) => input && input.focus()
										: undefined
								}
								className={classes.inputTextField}
								placeholder={answer.subtext}
								value={instance.text}
								onFocus={onFocusHandler}
								onChange={textOnChangeHandler}
								variant={secondary ? "standard" : "outlined"}
								size={"small"}
							/>
						}
					/>
				</div>
			)}
		</React.Fragment>
	);
});

export default InputCheckboxText;
