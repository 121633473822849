import React, { useEffect } from "react";
import { observer } from "mobx-react";

import useStores from "@hooks/useStores";

import { Box, Select, MenuItem } from "@material-ui/core";

const Travel_Distance = observer(({ question, answer, secondary }) => {
	const { travelDistanceStore: store } = useStores();
	const instance = store.findInstanceByAnswerId(answer._id);

	const onChangeHandler = (e) => instance.update(e.target.value);

	useEffect(() => {
		store.init(question, answer);
		return () => store.reset();
	}, []);

	return (
		<React.Fragment>
			{instance && (
				<React.Fragment>
					<h4>I will travel up to</h4>
					<Select value={instance.distance} onChange={onChangeHandler}>
						<MenuItem value="5">5 miles</MenuItem>
						<MenuItem value="10">10 miles</MenuItem>
						<MenuItem value="15">15 miles</MenuItem>
						<MenuItem value="25">25 miles</MenuItem>
						<MenuItem value="50">50 miles</MenuItem>
					</Select>
				</React.Fragment>
			)}
		</React.Fragment>
	);
});

export default Travel_Distance;
