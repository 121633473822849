import React, { useEffect } from "react";
import { observer } from "mobx-react";

import useStores from "@hooks/useStores";

import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";

const InputText = observer(({ question, answer, secondary }) => {
	const { inputTextStore: store } = useStores();
	const instance = store.findInstanceByAnswerId(answer._id);

	const onChangeHandler = (e) => instance.update(e.target.value);

	useEffect(() => {
		store.init(question, answer);
		return () => store.reset();
	}, []);

	return (
		<React.Fragment>
			{instance && (
				<Box p={{ xs: 4, sm: 7 }} borderBottom="1px solid rgb(238, 238, 238)">
					<TextField
						fullWidth
						id={answer._id}
						placeholder={answer.subtext}
						variant={"outlined"}
						onChange={onChangeHandler}
						InputProps={{
							style: {
								fontWeight: "400",
							},
						}}
					/>
				</Box>
			)}
		</React.Fragment>
	);
});

export default InputText;
