import React, { useEffect } from "react";
import { observer } from "mobx-react";

import useStores from "@hooks/useStores";

import DateFnsUtils from "@date-io/moment";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

const DateOnly = observer(({ question, answer, secondary }) => {
	const { dateOnlyStore: store } = useStores();
	const instance = store.findInstanceByAnswerId(answer._id);

	const onChangeHandler = (val) => instance.update("date", val);

	useEffect(() => {
		store.init(question, answer);
		return () => store.reset();
	}, []);

	return (
		<React.Fragment>
			{instance && (
				<MuiPickersUtilsProvider utils={DateFnsUtils}>
					<DatePicker
						minDate={Date.now()}
						variant="static"
						openTo="date"
						views={["year", "month", "date"]}
						label="Date"
						helperText="Choose your date"
						value={instance.date}
						onAccept={onChangeHandler}
						onChange={onChangeHandler}
					/>
				</MuiPickersUtilsProvider>
			)}
		</React.Fragment>
	);
});

export default DateOnly;
