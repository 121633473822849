import React, { useEffect, useRef } from "react";
import { observer } from "mobx-react";

import useStores from "@hooks/useStores";

import Radio from "@material-ui/core/Radio";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import useStyles from "@hooks/useStyles";
import Box from "@material-ui/core/Box";

const InputRadioText = observer(({ question, answer, secondary }) => {
	const classes = useStyles("LeadFunnel");
	const { inputRadioTextStore: store } = useStores();
	const instance = store.findInstanceByAnswerId(answer._id);

	const textOnChangeHandler = (e) => instance.updateText(e.target.value);
	const radioOnChangeHandler = () => instance.add();
	const onFocusHandler = () => instance.focus();

	useEffect(() => {
		store.init(question, answer);
		return () => store.reset();
	}, []);

	const textInputRef = useRef();
	useEffect(() => {
		if (instance?.isChecked) textInputRef.current.focus();
	}, [instance?.isChecked]);

	return (
		<React.Fragment>
			{instance && (
				<div className={secondary ? classes.inputBorder2 : classes.inputBorder}>
					<FormControlLabel
						classes={{
							root: secondary ? classes.formControl2 : classes.formControl,
							label: classes.formControlLabel,
						}}
						className={instance.isChecked && secondary && classes.checked}
						control={
							<Radio
								color={"primary"}
								name={"answer"}
								checked={instance.isChecked}
								onChange={radioOnChangeHandler}
								id={answer._id}
							/>
						}
						label={
							<TextField
								inputRef={textInputRef}
								className={classes.inputTextField}
								placeholder={answer.subtext}
								value={instance.text}
								onFocus={onFocusHandler}
								onChange={textOnChangeHandler}
								variant={secondary ? "standard" : "outlined"}
								size={"small"}
							/>
						}
					/>
				</div>
			)}
		</React.Fragment>
	);
});

export default InputRadioText;
