import React, { useEffect } from "react";
import useStores from "@hooks/useStores";
import { observer } from "mobx-react";

import TextField from "@material-ui/core/TextField";

const TextArea = observer(({ question, answer, secondary }) => {
	const { textAreaStore: store } = useStores();
	const instance = store.findInstanceByAnswerId(answer._id);

	const onChangeHandler = (e) => instance.update(e.target.value);

	useEffect(() => {
		store.init(question, answer);
		return () => store.reset();
	}, []);

	return (
		<React.Fragment>
			{instance && (
				<TextField
					multiline={true}
					maxRows={7}
					minRows={5}
					placeholder={answer.subtext}
					value={instance.text}
					onChange={onChangeHandler}
					variant={"outlined"}
				/>
			)}
		</React.Fragment>
	);
});

export default TextArea;
